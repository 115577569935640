<template>
	<div>
		<div class="mb-12">
			<div class="w-full space-y-1 md:w-1/2">
				<label class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-400">
					Timezone
				</label>
				<div class="relative">
					<span class="inline-block w-full rounded-md shadow-sm">
						<button
							@click="openTimezone = !openTimezone"
							type="button"
							aria-haspopup="listbox"
							aria-expanded="true"
							aria-labelledby="listbox-label"
							class="relative w-full py-2 pl-3 pr-10 text-left transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md cursor-default dark:bg-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
						>
							<span class="block truncate">
								{{ timezone.text }}
							</span>
							<span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
								<svg
									class="w-5 h-5 text-gray-400"
									viewBox="0 0 20 20"
									fill="none"
									stroke="currentColor"
								>
									<path
										d="M7 7l3-3 3 3m0 6l-3 3-3-3"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</span>
						</button>
					</span>

					<div v-if="openTimezone" class="absolute w-full mt-1 bg-white rounded-md shadow-lg">
						<ul
							tabindex="-1"
							role="listbox"
							aria-labelledby="listbox-label"
							aria-activedescendant="listbox-item-3"
							class="py-1 overflow-auto text-base leading-6 rounded-md shadow-xs max-h-60 focus:outline-none sm:text-sm sm:leading-5"
						>
							<li
								@click="changeTimezone(time)"
								v-for="time in timezones"
								:key="time.value"
								id="listbox-item-0"
								role="option"
								class="relative py-2 pl-6 pr-4 text-gray-900 cursor-default select-none"
							>
								<span class="block font-normal truncate">
									{{ time.text }}
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div v-if="availables.length > 0" class="mt- ">
				<div class="flex items-center mt-6 text-gray-600 dark:text-gray-300">
					<button @click="prevWeek()" class="mr-4 ">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="w-8 h-8 stroke-current"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
						</svg>
					</button>
					<span v-if="monday" class="mr-4 "> Week of {{ formatDate(monday) }} </span>
					<button @click="nextWeek()">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="w-8 h-8 stroke-current"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
						</svg>
					</button>
				</div>
				<div class="flex h-auto mt-2 border-b border-l border-r border-gray-300">
					<div
						class="flex-1 pb-4 border border-gray-300 dark:border-gray-400"
						v-for="(day, index) in weekdays.slice(0, 5)"
						:key="index"
					>
						<div v-if="index !== 5">
							<div
								class="text-center border-b border-gray-300 dark:border-gray-400 "
								:class="
									formatDate(todayDay) == formatDate(day)
										? 'bg-blue-300 font-bold text-blue-800'
										: 'bg-white dark:bg-gray-500 dark:text-gray-300 text-gray-500 '
								"
							>
								<div class="py-2 ">
									{{ formatWeekDay(day) }}
								</div>
							</div>
							<div class="px-1 mt-3 space-y-1">
								<h2 class="text-sm text-gray-500 uppercase font-base dark:text-gray-300">
									08:00 - 12:00
								</h2>
								<div v-for="(available, availIndex) in availables" :key="availIndex">
									<div
										v-if="available.mornings[index] == '0000'"
										class="flex items-center px-2 text-xs text-white rounded py-half"
										:class="'bg-' + availIndex"
									>
										{{ available.employee }}
										<svg
											v-if="available.allDays[index] == '000000000'"
											xmlns="http://www.w3.org/2000/svg"
											class="w-4 h-4 ml-2"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
									</div>
									<div
										v-else
										class="flex items-center px-2 text-xs text-white text-gray-500 line-through bg-gray-300 rounded py-half"
									>
										<span class="mr-2">
											{{ available.employee }}
										</span>
										<span
											v-for="(hour, hourIndex) in available.mornings[index]"
											:key="hourIndex"
											class="text-gray-500"
										>
											<svg
												v-if="hour == '0'"
												class="w-4 h-4 fill-current mr-half"
												version="1.1"
												viewBox="0 0 100 100"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="m50 6.25c-24.164 0-43.75 19.586-43.75 43.75s19.586 43.75 43.75 43.75 43.75-19.586 43.75-43.75-19.586-43.75-43.75-43.75zm-50 43.75c0-27.613 22.387-50 50-50s50 22.387 50 50-22.387 50-50 50-50-22.387-50-50z"
													fill-rule="evenodd"
												/>
											</svg>
											<svg
												v-else
												class="w-4 h-4 fill-current stroke-current mr-half"
												version="1.1"
												viewBox="0 0 100 100"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g>
													<path
														d="m92.125 50c0 2.8516-0.28516 5.6953-0.85547 8.4883-0.55469 2.707-1.3789 5.3555-2.457 7.9023-2.1172 5-5.1875 9.5469-9.0312 13.391s-8.3906 6.9141-13.391 9.0312c-2.5469 1.0781-5.1953 1.9023-7.9023 2.457-2.793 0.57031-5.6367 0.85547-8.4883 0.85547s-5.6953-0.28516-8.4883-0.85547c-2.707-0.55469-5.3555-1.3789-7.9023-2.457-5-2.1172-9.5469-5.1875-13.391-9.0312s-6.9141-8.3906-9.0312-13.391c-1.0781-2.5469-1.9023-5.1953-2.457-7.9023-0.57031-2.793-0.85547-5.6367-0.85547-8.4883s0.28516-5.6953 0.85547-8.4883c0.55469-2.707 1.3789-5.3555 2.457-7.9023 2.1172-5 5.1875-9.5469 9.0312-13.391s8.3906-6.9141 13.391-9.0312c2.5469-1.0781 5.1953-1.9023 7.9023-2.457 2.793-0.57031 5.6367-0.85547 8.4883-0.85547s5.6953 0.28516 8.4883 0.85547c2.707 0.55469 5.3555 1.3789 7.9023 2.457 5 2.1172 9.5469 5.1875 13.391 9.0312s6.9141 8.3906 9.0312 13.391c1.0781 2.5469 1.9023 5.1953 2.457 7.9023 0.57031 2.793 0.85547 5.6367 0.85547 8.4883m5.8594 0zc0 3.2461-0.32422 6.4844-0.97656 9.6641-0.63281 3.0898-1.5703 6.1055-2.7969 9.0078-2.4141 5.6992-5.9062 10.879-10.285 15.254-4.375 4.3789-9.5547 7.8711-15.254 10.285-2.9023 1.2266-5.918 2.1641-9.0078 2.7969-3.1797 0.65234-6.418 0.97656-9.6641 0.97656s-6.4844-0.32422-9.6641-0.97656c-3.0859-0.63281-6.1055-1.5703-9.0117-2.7969-5.6953-2.4141-10.875-5.9062-15.25-10.285-4.3789-4.375-7.8711-9.5547-10.285-15.254-1.2266-2.9023-2.1641-5.918-2.7969-9.0078-0.65234-3.1797-0.97656-6.418-0.97656-9.6641s0.32422-6.4844 0.97656-9.6641c0.63281-3.0859 1.5703-6.1055 2.7969-9.0117 2.4141-5.6953 5.9062-10.875 10.285-15.25 4.375-4.3789 9.5547-7.8711 15.25-10.285 2.9062-1.2266 5.9258-2.1641 9.0117-2.7969 3.1797-0.65234 6.418-0.97656 9.6641-0.97656s6.4844 0.32422 9.6641 0.97656c3.0898 0.63281 6.1055 1.5703 9.0078 2.7969 5.6992 2.4141 10.879 5.9062 15.254 10.285 4.3789 4.375 7.8711 9.5547 10.285 15.25 1.2266 2.9062 2.1641 5.9258 2.7969 9.0117 0.65234 3.1797 0.97656 6.418 0.97656 9.6641z"
														fill-rule="evenodd"
													/>
													<path
														d="m69.137 20.621c-2.0117 0.019531-3.9141 0.92578-5.1992 2.4766l-38.281 44.941c-1.2031 1.375-1.8047 3.1758-1.6719 5 0.13672 1.8203 0.99609 3.5156 2.3867 4.6992 1.3906 1.1875 3.1992 1.7656 5.0195 1.6094 1.8242-0.15625 3.5039-1.0391 4.6719-2.4453l38.281-44.941c1.7773-2.0234 2.1992-4.9062 1.0742-7.3555s-3.5859-4.0117-6.2812-3.9844z"
													/>
													<path
														d="m30.656 20.621c-2.6562 0.054687-5.043 1.6445-6.1172 4.0781-1.0703 2.4297-0.63672 5.2656 1.1172 7.2617l38.281 44.941c1.168 1.4062 2.8477 2.2891 4.6719 2.4453 1.8203 0.15625 3.6289-0.42188 5.0195-1.6094 1.3906-1.1836 2.25-2.8789 2.3867-4.6992 0.13281-1.8242-0.46875-3.625-1.6719-5l-38.281-44.941c-1.3281-1.6094-3.3203-2.5195-5.4062-2.4766z"
													/>
												</g>
											</svg>
										</span>
									</div>
								</div>
							</div>
							<div class="px-2 mt-4 space-y-1">
								<h2 class="mt-2 text-sm text-gray-500 uppercase font-base dark:text-gray-300">
									13:00 - 17:00
								</h2>
								<div v-for="(available, availIndex) in availables" :key="availIndex">
									<div
										v-if="available.afternoons[index] == '0000'"
										class="flex items-center px-2 text-xs text-white rounded py-half"
										:class="'bg-' + availIndex"
									>
										{{ available.employee }}

										<svg
											v-if="available.allDays[index] == '000000000'"
											xmlns="http://www.w3.org/2000/svg"
											class="w-4 h-4 ml-2"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
											/>
										</svg>
									</div>
									<div
										v-else
										class="flex items-center px-2 text-xs text-white text-gray-500 line-through bg-gray-300 rounded py-half"
									>
										<span class="mr-2">
											{{ available.employee }}
										</span>
										<span
											v-for="(hour, hourIndex) in available.afternoons[index]"
											:key="hourIndex"
											class="text-gray-500"
										>
											<svg
												v-if="hour == '0'"
												class="w-4 h-4 fill-current mr-half"
												version="1.1"
												viewBox="0 0 100 100"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="m50 6.25c-24.164 0-43.75 19.586-43.75 43.75s19.586 43.75 43.75 43.75 43.75-19.586 43.75-43.75-19.586-43.75-43.75-43.75zm-50 43.75c0-27.613 22.387-50 50-50s50 22.387 50 50-22.387 50-50 50-50-22.387-50-50z"
													fill-rule="evenodd"
												/>
											</svg>
											<svg
												v-else
												class="w-4 h-4 fill-current stroke-current mr-half"
												version="1.1"
												viewBox="0 0 100 100"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g>
													<path
														d="m92.125 50c0 2.8516-0.28516 5.6953-0.85547 8.4883-0.55469 2.707-1.3789 5.3555-2.457 7.9023-2.1172 5-5.1875 9.5469-9.0312 13.391s-8.3906 6.9141-13.391 9.0312c-2.5469 1.0781-5.1953 1.9023-7.9023 2.457-2.793 0.57031-5.6367 0.85547-8.4883 0.85547s-5.6953-0.28516-8.4883-0.85547c-2.707-0.55469-5.3555-1.3789-7.9023-2.457-5-2.1172-9.5469-5.1875-13.391-9.0312s-6.9141-8.3906-9.0312-13.391c-1.0781-2.5469-1.9023-5.1953-2.457-7.9023-0.57031-2.793-0.85547-5.6367-0.85547-8.4883s0.28516-5.6953 0.85547-8.4883c0.55469-2.707 1.3789-5.3555 2.457-7.9023 2.1172-5 5.1875-9.5469 9.0312-13.391s8.3906-6.9141 13.391-9.0312c2.5469-1.0781 5.1953-1.9023 7.9023-2.457 2.793-0.57031 5.6367-0.85547 8.4883-0.85547s5.6953 0.28516 8.4883 0.85547c2.707 0.55469 5.3555 1.3789 7.9023 2.457 5 2.1172 9.5469 5.1875 13.391 9.0312s6.9141 8.3906 9.0312 13.391c1.0781 2.5469 1.9023 5.1953 2.457 7.9023 0.57031 2.793 0.85547 5.6367 0.85547 8.4883m5.8594 0zc0 3.2461-0.32422 6.4844-0.97656 9.6641-0.63281 3.0898-1.5703 6.1055-2.7969 9.0078-2.4141 5.6992-5.9062 10.879-10.285 15.254-4.375 4.3789-9.5547 7.8711-15.254 10.285-2.9023 1.2266-5.918 2.1641-9.0078 2.7969-3.1797 0.65234-6.418 0.97656-9.6641 0.97656s-6.4844-0.32422-9.6641-0.97656c-3.0859-0.63281-6.1055-1.5703-9.0117-2.7969-5.6953-2.4141-10.875-5.9062-15.25-10.285-4.3789-4.375-7.8711-9.5547-10.285-15.254-1.2266-2.9023-2.1641-5.918-2.7969-9.0078-0.65234-3.1797-0.97656-6.418-0.97656-9.6641s0.32422-6.4844 0.97656-9.6641c0.63281-3.0859 1.5703-6.1055 2.7969-9.0117 2.4141-5.6953 5.9062-10.875 10.285-15.25 4.375-4.3789 9.5547-7.8711 15.25-10.285 2.9062-1.2266 5.9258-2.1641 9.0117-2.7969 3.1797-0.65234 6.418-0.97656 9.6641-0.97656s6.4844 0.32422 9.6641 0.97656c3.0898 0.63281 6.1055 1.5703 9.0078 2.7969 5.6992 2.4141 10.879 5.9062 15.254 10.285 4.3789 4.375 7.8711 9.5547 10.285 15.25 1.2266 2.9062 2.1641 5.9258 2.7969 9.0117 0.65234 3.1797 0.97656 6.418 0.97656 9.6641z"
														fill-rule="evenodd"
													/>
													<path
														d="m69.137 20.621c-2.0117 0.019531-3.9141 0.92578-5.1992 2.4766l-38.281 44.941c-1.2031 1.375-1.8047 3.1758-1.6719 5 0.13672 1.8203 0.99609 3.5156 2.3867 4.6992 1.3906 1.1875 3.1992 1.7656 5.0195 1.6094 1.8242-0.15625 3.5039-1.0391 4.6719-2.4453l38.281-44.941c1.7773-2.0234 2.1992-4.9062 1.0742-7.3555s-3.5859-4.0117-6.2812-3.9844z"
													/>
													<path
														d="m30.656 20.621c-2.6562 0.054687-5.043 1.6445-6.1172 4.0781-1.0703 2.4297-0.63672 5.2656 1.1172 7.2617l38.281 44.941c1.168 1.4062 2.8477 2.2891 4.6719 2.4453 1.8203 0.15625 3.6289-0.42188 5.0195-1.6094 1.3906-1.1836 2.25-2.8789 2.3867-4.6992 0.13281-1.8242-0.46875-3.625-1.6719-5l-38.281-44.941c-1.3281-1.6094-3.3203-2.5195-5.4062-2.4766z"
													/>
												</g>
											</svg>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import format from "date-fns/format";
	import addDays from "date-fns/addDays";
	import subDays from "date-fns/subDays";
	import startOfWeek from "date-fns/startOfWeek";

	import EmployeeService from "@/services/EmployeeService";
	import { Calendar } from "@fullcalendar/core";
	import dayGridPlugin from "@fullcalendar/daygrid";

	console.log(Calendar);
	export default {
		name: "addEngagement",
		components: {},
		data() {
			return {
				showWeek: false,
				calendarOptions: {
					plugins: [dayGridPlugin],
					initialView: "dayGridWeek",
				},
				employees: {},
				pageLoaded: false,
				today: new Date(),
				todayDay: new Date(),
				weekdays: [],
				dates: [],
				monday: null,
				selected: {},
				schedules: [],
				errors: [],
				submitted: false,
				availables: [],
				openTimezone: false,
				timezone: {
					value: "America/Los_Angeles",
					text: "Pacific",
				},
				timezones: [
					{
						value: "America/Los_Angeles",
						text: "Pacific",
					},
					{
						value: "America/Denver",
						text: "Mountain",
					},
					{
						value: "America/Chicago",
						text: "Central",
					},
					{
						value: "America/New_York",
						text: "Eastern",
					},
					//   {
					//     value: "Europe/London",
					//     text: "London",
					//   },
				],
				workweek: [{}],
			};
		},
		mounted() {
			this.getDays();
			EmployeeService.getEmployeeList().then(employeeList => this.employees = employeeList)
		},

		methods: {
			getDays() {
				this.monday = startOfWeek(new Date(this.today), { weekStartsOn: 1 });
				console.log(this.monday);
				var i = 0;
				var day = null;
				for (i = 0; i < 6; i++) {
					day = addDays(new Date(this.monday), i);
					console.log(this.monday);
					this.weekdays.push(day);
				}
				console.log("weekdays: " + this.weekdays);
				this.changeTimezone(this.timezone);
			},
			dateFormat() {
				this.today = format(new Date(), "yyyy-MM-dd");
			},
			customFormatter(date) {
				this.startDate = format(new Date(date), "yyyy-MM-dd");
			},
			getFormat() {
				return this.format(new Date(), "dddd", { locale: window.locale });
			},

			changeTimezone(tz) {
				this.openTimezone = false;
				this.timezone = tz;
				this.showWeek = true;
				this.callMicrosoft();
			},

			async callMicrosoft() {
				console.log("calling all ");
				var scheduleEmails = Object.entries(
					await EmployeeService.getEmployeeList()
					).map(employee => employee[1].email);

				// calling microsoft graph
				console.log("format ", format(this.weekdays[0], "yyyy-MM-dd") + "T00:00:00");
				this.availables = [];
				this.schedules = [];
				const myBody = {
					schedules: scheduleEmails,
					startTime: {
						dateTime: format(this.weekdays[0], "yyyy-MM-dd") + "T00:00:00",
						timeZone: this.timezone.value,
					},
					endTime: {
						dateTime: format(this.weekdays[5], "yyyy-MM-dd") + "T00:00:00",
						timeZone: this.timezone.value,
					},
					availabilityViewInterval: 60,
				};
				this.$httpMS
					.post("/me/calendar/getSchedule", myBody)
					.then((response) => {
						this.schedules = response.data;
						this.buildSchedules();
					})
					.catch(function(error) {
						console.log("error: " + error);
					});
			}, // end callMicrosoft()
			buildSchedules() {
				for (var i = 0; i < this.schedules.value.length; i++) {
					this.availables.push({
						employee: this.schedules.value[i].scheduleId.split("@")[0],
						mornings: [
							this.schedules.value[i].availabilityView.substr(8, 4),
							this.schedules.value[i].availabilityView.substr(32, 4),
							this.schedules.value[i].availabilityView.substr(56, 4),
							this.schedules.value[i].availabilityView.substr(80, 4),
							this.schedules.value[i].availabilityView.substr(106, 4),
						],
						afternoons: [
							this.schedules.value[i].availabilityView.substr(13, 4),
							this.schedules.value[i].availabilityView.substr(37, 4),
							this.schedules.value[i].availabilityView.substr(61, 4),
							this.schedules.value[i].availabilityView.substr(85, 4),
							this.schedules.value[i].availabilityView.substr(109, 4),
						],
						allDays: [
							this.schedules.value[i].availabilityView.substr(8, 9),
							this.schedules.value[i].availabilityView.substr(32, 9),
							this.schedules.value[i].availabilityView.substr(56, 9),
							this.schedules.value[i].availabilityView.substr(80, 9),
							this.schedules.value[i].availabilityView.substr(106, 9),
						],
					});
				}
			}, // end buildSchedules()
			prevWeek() {
				this.weekdays = [];
				this.today = subDays(new Date(this.today), 7);
				this.getDays();
			},
			nextWeek() {
				this.weekdays = [];
				this.today = addDays(new Date(this.today), 7);
				this.getDays();
			},
			formatDate: function(value) {
				return format(new Date(value), "MMM dd");
			},
			formatWeekDay: function(value) {
				var weekdays = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
				return weekdays[value.getDay()] + " - " + format(new Date(value), "MMM dd");
			},
			getDayWeek(value) {
				return format(new Date(value), "ccc");
			},
		},

		computed: {
			filteredWeekdays() {
				return this.myArray.filter((item) => {
					// Many more calculations
					if (item.id !== 0) {
						return true;
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style scoped>
	.w-11 {
		width: 2.75rem;
	}
	.mr-half {
		margin-right: 0.08rem;
	}
	.py-half {
		padding-top: 0.15rem;
		padding-bottom: 0.15rem;
	}
	.space-y-1 > :not(template) ~ :not(template) {
		--space-y-reverse: 0;
		margin-top: calc(0.15rem * calc(1 - var(--space-y-reverse)));
		margin-bottom: calc(0.15rem * var(--space-y-reverse));
	}
	.bg-0 {
		@apply bg-gray-500 text-gray-100 !important;
	}
	.bg-1 {
		@apply bg-blue-500 text-blue-100 !important;
	}
	.bg-2 {
		@apply bg-indigo-600 !important;
	}
	.bg-3 {
		@apply bg-red-400 !important;
	}
	.bg-4 {
		@apply bg-yellow-400 !important;
	}
	.bg-5 {
		@apply bg-blue-600 !important;
	}
	.bg-6 {
		@apply bg-red-700 !important;
	}
	.bg-7 {
		@apply bg-purple-500 !important;
	}
	.bg-8 {
		@apply bg-blue-300 !important;
	}
	.bg-9 {
		@apply bg-green-500 !important;
	}
	.bg-17 {
		@apply bg-yellow-500 !important;
	}
	.bg-10 {
		@apply bg-gray-800 !important;
	}
	.bg-11 {
		@apply bg-blue-400 !important;
	}
	.bg-12 {
		@apply bg-blue-500 !important;
	}
	.bg-13 {
		@apply bg-red-600 !important;
	}
	.bg-14 {
		@apply bg-purple-800 !important;
	}
	.bg-15 {
		@apply bg-blue-400 !important;
	}
	.bg-16 {
		@apply bg-blue-800 !important;
	}
</style>
